import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/simple-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Professor Resources`}</h1>
    <p><strong parentName="p"><em parentName="strong">{`NOTE: This resource is for instructors facilitating this course through Cedarville University's offering of VCD-3700. Unauthorized use is prohibited.`}</em></strong></p>
    <p>{`Welcome to facilitating this course! We're glad you're willing to take on this great honor to guide students through learning this important subject. We hope students emerge with a greater empathy for users and a strogner sense of what it means to design in this field.`}</p>
    <p>{`In the weeks leading up to the course you may receive emails from students about course logistics. Generally, it is OK to answer them with a simple note about the course's start date and to reassure them that all information they need will be provided on the first day of class through the University LMS. If they present questions or concerns that seem urgent you do not know how to answer, please reach out to the department chairperson for assistance.`}</p>
    <p>{`What follows is a series of lesson plans including suggested announcements to send out to students, notes about assignments, and discussion prompts that should be entered in the LMS following the structure covered in your orientation.`}</p>
    <h2>{`Weekly Guides`}</h2>
    <p><strong parentName="p">{`Please work ahead to ensure all discussion prompts are set up in the LMS with at least one week of margin.`}</strong></p>
    <div className="lesson-plans">
      <div className="lesson-plan">
        <h3>{`Introduction`}</h3>
        <div className="lesson-plan__notes">
          <h4>{`Notes`}</h4>
          <p><a parentName="p" {...{
              "href": "/lesson-plans/discussions/00-discussions/"
            }}>{`Discussion prompts`}</a></p>
          <p>{`Above all, keep in mind that this course is about User Experience... and as we try to influence students on this topic modeling it ourselves is key. Be gracious, honest, and considerate even when holding them to a high bar for quality work.`}</p>
        </div>
        <div className="lesson-plan__tasks">
          <h4>{`Action items`}</h4>
          <ul {...{
            "className": "contains-task-list"
          }}>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Send out `}<a parentName="li" {...{
                "href": "/lesson-plans/announcements/00-intro/"
              }}>{`Welcome announcement`}</a>{` on first day of class`}</li>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Keep an eye on discussion forums and interact with students as they post.`}</li>
          </ul>
        </div>
      </div>
      <div className="lesson-plan">
        <h3>{`Lesson 1`}</h3>
        <div className="lesson-plan__notes">
          <h4>{`Notes`}</h4>
          <p><a parentName="p" {...{
              "href": "/lesson-plans/discussions/01-discussions/"
            }}>{`Discussion prompts`}</a></p>
          <p>{`Project deliverable 1A: Look to ensure students are considering more than just visual aspects but truly task-oriented, navigational, or labeling issues with their proposed site. Steer any misplaced plans toward these or encourage students to choose different topics.`}</p>
        </div>
        <div className="lesson-plan__tasks">
          <h4>{`Action items`}</h4>
          <ul {...{
            "className": "contains-task-list"
          }}>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Send `}<a parentName="li" {...{
                "href": "/lesson-plans/announcements/01-lesson-1/"
              }}>{`announcement`}</a>{` at start of week to remind of upcoming stuffs.`}</li>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Review participation from the previous week and complete the Roster Verification the Office of Registrar likely sent out. Any students who didn't complete the intro activities may be marked as "absent".`}</li>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Engage in discussions.`}</li>
          </ul>
        </div>
      </div>
      <div className="lesson-plan">
        <h3>{`Lesson 2`}</h3>
        <div className="lesson-plan__notes">
          <h4>{`Notes`}</h4>
          <p><a parentName="p" {...{
              "href": "/lesson-plans/discussions/02-discussions/"
            }}>{`Discussion prompts`}</a></p>
          <p>{`This week involves a different kind of activity in the discussions, the Observation Exercise. Be sure to be familiar with it.`}</p>
          <p>{`Project deliverable 1B: As before, look to ensure students are focusing on specific tasks for their users to accomplish (and consider whether the tasks are practical, likely tasks for the actual audience). They should also begin to demonstrate a developing user-centered mindset.`}</p>
        </div>
        <div className="lesson-plan__tasks">
          <h4>{`Action items`}</h4>
          <ul {...{
            "className": "contains-task-list"
          }}>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Send `}<a parentName="li" {...{
                "href": "/lesson-plans/announcements/02-lesson-2/"
              }}>{`announcement`}</a>{` at start of week to remind of upcoming activities, particularly the different activity mentioned above.`}</li>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Grade P1A (last lesson deliverable)`}</li>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Engage in discussions.`}</li>
          </ul>
        </div>
      </div>
      <div className="lesson-plan">
        <h3>{`Lesson 3`}</h3>
        <div className="lesson-plan__notes">
          <h4>{`Notes`}</h4>
          <p><a parentName="p" {...{
              "href": "/lesson-plans/discussions/03-discussions/"
            }}>{`Discussion prompts`}</a></p>
          <p>{`This week involves another different kind of exercise in the discussions, the Sketching Exercise. Emphasis should be placed on `}<em parentName="p">{`rapid prototyping`}</em>{` not artistic renderings. While we're looking for depictions that successfully communicate, we're also looking for critical thought about the objects themselves to see students digesting the topics to date.`}</p>
          <p>{`Project Deliverable 1C: Wireframes should be `}<strong parentName="p">{`LOW FIDELITY`}</strong>{` but true wireframes with a) enough content to determine what is intended, particularly towards accomplishing a task and b) loose enough without too much stress on visual style.`}</p>
        </div>
        <div className="lesson-plan__tasks">
          <h4>{`Action items:`}</h4>
          <ul {...{
            "className": "contains-task-list"
          }}>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Send `}<a parentName="li" {...{
                "href": "/lesson-plans/announcements/03-lesson-3/"
              }}>{`announcement`}</a>{` at start of week to remind of upcoming activities, particularly the different one mentioned above.`}</li>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Grade P1B (last lesson deliverable)`}</li>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Engage in discussions.`}</li>
          </ul>
        </div>
      </div>
      <div className="lesson-plan">
        <h3>{`Lesson 4`}</h3>
        <div className="lesson-plan__notes">
          <h4>{`Notes`}</h4>
          <p><a parentName="p" {...{
              "href": "/lesson-plans/discussions/04-discussions/"
            }}>{`Discussion prompts`}</a></p>
          <p>{`Discussions settle into a regular rhythm this week and moving forward.`}</p>
          <p>{`Project Deliverable 1D: Looking now for them to have tested their wireframe and task-oriented use cases with actual users. Successful submissions follow the provided structure, especially having a protocol for their test sessions that is intentional and appears to have `}<em parentName="p">{`actually been used`}</em>{` during test. In report, look for critical thought, diligent work, and stress on the task at hand. Willingness to adjust based on feedback rather than just checking the box and calling it "done". `}<strong parentName="p">{`In Preparation, draw attention to the sample report that includes a sample protocol.`}</strong></p>
        </div>
        <div className="lesson-plan__tasks">
          <h4>{`Action items:`}</h4>
          <ul {...{
            "className": "contains-task-list"
          }}>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Send `}<a parentName="li" {...{
                "href": "/lesson-plans/announcements/04-lesson-4/"
              }}>{`announcement`}</a>{` at start of week`}</li>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Grade P1C (last lesson deliverable)`}</li>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Engage in discussions.`}</li>
          </ul>
        </div>
      </div>
      <div className="lesson-plan">
        <h3>{`Lesson 5`}</h3>
        <div className="lesson-plan__notes">
          <h4>{`Notes`}</h4>
          <p><a parentName="p" {...{
              "href": "/lesson-plans/discussions/05-discussions/"
            }}>{`Discussion prompts`}</a></p>
          <p>{`Start to use InVision in coming weeks, so make a separate announcement with the semester's InVision Access code. Also the first week to officially use Sketch for deliverables, so pointing students to resources on Course Website is good if they need to learn Sketch.`}</p>
          <p>{`Project Deliverable 1E: Now we can move into using digital tools such as Sketch so we should see more refinement to wireframes but still not any real visual style. Be sure to call this out to help students who tend to want to more on to full style to still stay focused on the skeleton layer of the project.`}</p>
        </div>
        <div className="lesson-plan__tasks">
          <h4>{`Action items`}</h4>
          <ul {...{
            "className": "contains-task-list"
          }}>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Send `}<a parentName="li" {...{
                "href": "/lesson-plans/announcements/05-lesson-5/"
              }}>{`announcement`}</a>{` at start of week`}</li>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Send `}<a parentName="li" {...{
                "href": "/lesson-plans/announcements/05b-sketch/"
              }}>{`sketch announcement`}</a>{` at start of week`}</li>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Send `}<a parentName="li" {...{
                "href": "/lesson-plans/announcements/05c-invision/"
              }}>{`invision announcement`}</a>{` at start of week`}</li>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Grade P1D (last lesson deliverable)`}</li>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Engage in discussions.`}</li>
          </ul>
        </div>
      </div>
      <div className="lesson-plan">
        <h3>{`Lesson 6`}</h3>
        <div className="lesson-plan__notes">
          <h4>{`Notes`}</h4>
          <p><a parentName="p" {...{
              "href": "/lesson-plans/discussions/06-discussions/"
            }}>{`Discussion prompts`}</a></p>
          <p>{`Project Deliverable 1F: First Sketch deliverable that we sync to InVision. Looking now to give detailed feedback on visual style, but keeping in mind the tasks users are aiming to accomplish and how the visual style assists/improves versus detracts. More feedback now saves time on the final submission and gives students a chance to earn best grade possible.`}</p>
          <p>{`Next week is a big week that we'll foreshadow for folks in this week's announcement: the final deliverable is due next week; the midterm exam is next week; and Project Deliverable 2A is due next week.`}</p>
        </div>
        <div className="lesson-plan__tasks">
          <h4>{`Action items`}</h4>
          <ul {...{
            "className": "contains-task-list"
          }}>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Send `}<a parentName="li" {...{
                "href": "/lesson-plans/announcements/06-lesson-6/"
              }}>{`announcement`}</a>{` at start of week`}</li>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Grade P1E (last lesson deliverable)`}</li>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Engage in discussions.`}</li>
          </ul>
        </div>
      </div>
      <div className="lesson-plan">
        <h3>{`Exam week`}</h3>
        <div className="lesson-plan__notes">
          <h4>{`Notes`}</h4>
          <p>{`Students take the midterm exam and submit final for P1 including the process book. They also need to submit their proposals for P2.`}</p>
        </div>
        <div className="lesson-plan__tasks">
          <h4>{`Action items`}</h4>
          <ul {...{
            "className": "contains-task-list"
          }}>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Send `}<a parentName="li" {...{
                "href": "/lesson-plans/announcements/midterms/"
              }}>{`announcement`}</a>{` at start of week`}</li>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Grade P1F (last lesson deliverable)`}</li>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Try to stay on top exam, P1 final, and P2A submissions early this week to help reduce load next week. Most terms this comes before a break that should give you time to grade these before the next lesson starts`}</li>
          </ul>
        </div>
      </div>
      <div className="lesson-plan">
        <h3>{`Lesson 7`}</h3>
        <div className="lesson-plan__notes">
          <h4>{`Notes`}</h4>
          <p><a parentName="p" {...{
              "href": "/lesson-plans/discussions/07-discussions/"
            }}>{`Discussion prompts`}</a></p>
          <p>{`Project Deliverable 2B: Give students head's up about user research and methods. They will greatly benefit from going into any sessions with a protocol or questionnaire rather than trying to "wing it"---and such a tool is required in the appendix for this submission.`}</p>
        </div>
        <div className="lesson-plan__tasks">
          <h4>{`Action items`}</h4>
          <ul {...{
            "className": "contains-task-list"
          }}>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Send `}<a parentName="li" {...{
                "href": "/lesson-plans/announcements/07-lesson-7/"
              }}>{`announcement`}</a>{` at start of week`}</li>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Grade P2A (last lesson deliverable) first to ensure folks have feedback heading into their next deliverable.`}</li>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Grade P1F (last lesson deliverable) and Midterm Exam as you're able. Hopefully a break is occurring or has occurred that allowed you to get these done.`}</li>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Engage in discussions.`}</li>
          </ul>
        </div>
      </div>
      <div className="lesson-plan">
        <h3>{`Lesson 8`}</h3>
        <div className="lesson-plan__notes">
          <h4>{`Notes`}</h4>
          <p><a parentName="p" {...{
              "href": "/lesson-plans/discussions/08-discussions/"
            }}>{`Discussion prompts`}</a></p>
          <p>{`Project Deliverable 2C: Stress task-focused design in this deliverable. Students should be demonstrating a strong sense of key tasks users will complete in their application, even if they don't plan to prototype all of those tasks. Evaluate whether the task they've chosen to work with make sense, and seem indeed to be key features of the application.`}</p>
        </div>
        <div className="lesson-plan__tasks">
          <h4>{`Action items`}</h4>
          <ul {...{
            "className": "contains-task-list"
          }}>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Send `}<a parentName="li" {...{
                "href": "/lesson-plans/announcements/08-lesson-8/"
              }}>{`announcement`}</a>{` at start of week`}</li>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Grade P2B (last lesson deliverable)`}</li>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Engage in discussions.`}</li>
          </ul>
        </div>
      </div>
      <div className="lesson-plan">
        <h3>{`Lesson 9`}</h3>
        <div className="lesson-plan__notes">
          <h4>{`Notes`}</h4>
          <p><a parentName="p" {...{
              "href": "/lesson-plans/discussions/09-discussions/"
            }}>{`Discussion prompts`}</a></p>
          <p>{`Project Deliverable 2D: This is similar to the previous deliverable from P1 so they should have some experience with this. Again continue to evaluate the task they're designing and the strength with which they are executing on it from a user-focused angle.`}</p>
        </div>
        <div className="lesson-plan__tasks">
          <h4>{`Action items`}</h4>
          <ul {...{
            "className": "contains-task-list"
          }}>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Send `}<a parentName="li" {...{
                "href": "/lesson-plans/announcements/09-lesson-9/"
              }}>{`announcement`}</a>{` at start of week`}</li>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Grade P2C (last lesson deliverable)`}</li>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Engage in discussions.`}</li>
          </ul>
        </div>
      </div>
      <div className="lesson-plan">
        <h3>{`Lesson 10`}</h3>
        <div className="lesson-plan__notes">
          <h4>{`Notes`}</h4>
          <p><a parentName="p" {...{
              "href": "/lesson-plans/discussions/10-discussions/"
            }}>{`Discussion prompts`}</a></p>
          <p>{`Similar deliverables as before; its all in the execution now.`}</p>
        </div>
        <div className="lesson-plan__tasks">
          <h4>{`Action items`}</h4>
          <ul {...{
            "className": "contains-task-list"
          }}>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Send `}<a parentName="li" {...{
                "href": "/lesson-plans/announcements/10-lesson-10/"
              }}>{`announcement`}</a>{` at start of week`}</li>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Grade P2D (last lesson deliverable)`}</li>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Engage in discussions.`}</li>
          </ul>
        </div>
      </div>
      <div className="lesson-plan">
        <h3>{`Lesson 11`}</h3>
        <div className="lesson-plan__notes">
          <h4>{`Notes`}</h4>
          <p><a parentName="p" {...{
              "href": "/lesson-plans/discussions/11-discussions/"
            }}>{`Discussion prompts`}</a></p>
          <p>{`Similar deliverables as before; its all in the execution now.`}</p>
        </div>
        <div className="lesson-plan__tasks">
          <h4>{`Action items`}</h4>
          <ul {...{
            "className": "contains-task-list"
          }}>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Send `}<a parentName="li" {...{
                "href": "/lesson-plans/announcements/11-lesson-11/"
              }}>{`announcement`}</a>{` at start of week`}</li>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Grade P2E (last lesson deliverable)`}</li>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Engage in discussions.`}</li>
          </ul>
        </div>
      </div>
      <div className="lesson-plan">
        <h3>{`Lesson 12`}</h3>
        <div className="lesson-plan__notes">
          <h4>{`Notes`}</h4>
          <p><a parentName="p" {...{
              "href": "/lesson-plans/discussions/12-discussions/"
            }}>{`Discussion prompts`}</a></p>
          <p>{`Similar deliverables as before; its all in the execution now.`}</p>
        </div>
        <div className="lesson-plan__tasks">
          <h4>{`Action items`}</h4>
          <ul {...{
            "className": "contains-task-list"
          }}>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Send `}<a parentName="li" {...{
                "href": "/lesson-plans/announcements/12-lesson-12/"
              }}>{`announcement`}</a>{` at start of week`}</li>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`Grade P2F (last lesson deliverable)`}</li>
            <li parentName="ul" {...{
              "className": "task-list-item"
            }}><input parentName="li" {...{
                "type": "checkbox",
                "checked": false,
                "disabled": true
              }}></input>{` `}{`NO discussion this week; Interactive Manifesto instead.`}</li>
          </ul>
        </div>
      </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      